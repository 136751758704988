"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.for-each");

require("core-js/modules/es.array.map");

require("core-js/modules/web.dom-collections.for-each");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2"));

var _api = require("./api");

var _Form = _interopRequireDefault(require("./Form.json"));

var _xqjrPluginCommon = require("xqjr-plugin-common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
// ----------------------------------------------------- 构建表格配置，包括基本项、操作
var columns = _Form.default.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    // fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
});

columns.push({
  width: 120,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
});

_Form.default.list.forEach(function (item, index) {
  if (item.type === "select" && item.model === "customerName") {
    _Form.default.list[index].options.options = JSON.parse(sessionStorage.getItem("customersList"));
    return;
  }
}); // ----------------------------------------------------- 生成 mixin


var mixin = new _xqjrPluginCommon.CurdMixin({
  retrieveReq: _api.retrieveReq,
  createReq: _api.createReq,
  deleteReq: _api.deleteReq,
  updateReq: _api.updateReq,
  deleteBatchReq: _api.deleteBatchReq,
  RecordForm: _Form.default,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

var _default = {
  components: {},
  mixins: [mixin],
  data: function data() {
    return {};
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    comfirm: function comfirm(record) {
      var that = this;
      this.$confirm({
        title: "充值确认",
        content: "确定充值到账了吗",
        onOk: function onOk() {
          (0, _api.updateStatusById)(record.id).then(function (res) {
            that.retrieveRecord();
          });
        },
        onCancel: function onCancel() {}
      });
    },
    editInvoiceOk: function editInvoiceOk() {
      var _this = this;

      this.$refs.kfb.getData().then(function (values) {
        console.log(_this.tempRecord);
        console.log("values");
        console.log(values);
        (0, _api.createInvoiceReq)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, values), _this.tempRecord), {}, {
          address: "",
          invoiceNo: "",
          phone: "",
          amount: _this.tempRecord.noInvoiceAmount,
          customerRechargeId: _this.tempRecord.id
        })).then(function (res) {
          if (res.success === true) {
            _this.$message.success("添加成功");

            _this.retrieveRecord();

            _this.editCancel();
          } else {
            _this.$message.error("添加失败");
          }

          _this.toggleSpinning();
        }).catch(function (err) {
          console.log("debug log --> ", err);

          _this.toggleSpinning();
        });
      });
    }
  }
};
exports.default = _default;