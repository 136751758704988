"use strict";

var _interopRequireDefault = require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/data/jenkins/workspace/dev-e-youhuo/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/objectSpread2"));

var _Folder = _interopRequireDefault(require("./components/Folder"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    folder: _Folder.default
  },
  data: function data() {
    return {
      breadListLast: [],
      fileInfo: {
        repoId: null,
        parentId: "0"
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)(["project"])), {}, {
    projectName: function projectName() {
      return this.project.projectName;
    },
    repoId: function repoId() {
      return this.project.repoId;
    }
  }),
  watch: {
    repoId: function repoId(val) {
      this.fileInfo.repoId = val;
    }
  },
  created: function created() {
    this.fileInfo.repoId = this.repoId;
  },
  methods: {
    toPath: function toPath(flag, id) {
      // 面包屑的点击'跳转路径'(非真跳转)
      if (flag < 0) {
        // 如果该标记等于-1说明是回收站或者修改历史,将不进行请求跳转,0是资料库首页,1是各层级文件夹
        return;
      }

      if (flag === 0) {
        this.breadListLast = [];
        this.$refs.folder.init();
      } else if (flag === 1) {
        var tempList = [];

        for (var i = 0; i < this.breadListLast.length; i++) {
          // 更新面包屑导航
          var bread = this.breadListLast[i];
          tempList.push(bread);

          if (bread.id === id) {
            this.breadListLast = tempList;
            break;
          }
        }

        if (this.fileInfo.repoId !== id) {
          this.fileInfo.parentId = id;
        } else {
          this.fileInfo.parentId = "0";
        }

        this.$refs.folder.toFilesList(this.fileInfo);
      }
    },
    // 接收子组件所传数据
    listenTochildEvent: function listenTochildEvent(val) {
      if (this.breadListLast.length <= 0 || this.breadListLast[this.breadListLast.length - 1].id !== val.id) {
        this.breadListLast.push(val);
      }
    }
  }
};
exports.default = _default;