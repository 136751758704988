"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      json: [// {
      //   title: "基础信息",
      //   item: [
      //     {
      //       title: "公司信息",
      //       path: "/basic/company"
      //     },
      //     // {
      //     //   title: "公司资料库",
      //     //   path: "/document"
      //     // },
      //     {
      //       title: "上游客户",
      //       path: "/basic/customer"
      //     },
      //     {
      //       title: "自由职业者",
      //       path: "/basic/freelance"
      //     }
      //   ]
      // },
      {
        title: "合同管理",
        item: [{
          title: "公司合同",
          path: "/contract/customerContract"
        }, {
          title: "自由职业者合同",
          path: "/contract/freelanceContract"
        } // {
        //   title: "第三方合同",
        //   path: "/contract/contracts"
        // }
        ]
      }, {
        title: "任务管理",
        item: [{
          title: "上游任务管理",
          path: "/task/customerTask"
        } // {
        //   title: "平台接包管理",
        //   path: "/task/accept"
        // },
        // {
        //   title: "公司任务管理",
        //   path: "/task/manage"
        // },
        // {
        //   title: "平台分包管理",
        //   path:  "/task/freelanceTask"
        // }
        ]
      }, {
        title: "发放管理",
        item: [{
          title: "费用发放",
          path: "/provide/customerOrder"
        }, {
          title: "发放流水",
          path: "/provide/freelancePayRecord"
        } // {
        //   title: "公司任务管理",
        //   path: "/task/manage"
        // },
        // {
        //   title: "平台分包管理",
        //   path:  "/task/record"
        // }
        ]
      }, {
        title: "费用管理",
        item: [{
          title: "充值账户",
          path: "/cost/account"
        }, {
          title: "余额明细",
          path: "/cost/customerBalance"
        }, {
          title: "费用明细",
          path: "/cost/customerRecord"
        }, {
          title: "上传充值记录",
          path: "/cost/customerRecharge"
        }]
      }, {
        title: "发票管理",
        item: [// {
        //   title: "发票抬头",
        //   path: "/bill/index"
        // },
        {
          title: "发票明细",
          path: "/invoice/detail"
        }, {
          title: "发票申请",
          path: "/invoice/customerInvoice"
        }, {
          title: "发票收件人",
          path: "/invoice/contacts"
        }]
      }]
    };
  },
  methods: {
    goto: function goto(path) {
      this.$router.push({
        path: path
      });
    }
  }
};
exports.default = _default;